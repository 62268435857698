import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
 import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
@Injectable()
export class PMS_goals_service {
	
	currentUser;
	currentUserDetails;
    

    constructor(private http: HttpClient) { 
    
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        
    }
     getEvaluationGoals(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_get_goals`,det);
    }
    getEvaluationGoalsDesc(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_get_goals_desc`,det);
    }
    
    /****************Self Evaluation************************/
    addSelfEvaluation(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_add_self_evaluation`,det);
    }
     updateSelfEvaluation(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_update_self_evaluation`,det);
    }
    addReviewStatus(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_add_review_status`,det);
    }
    getEvaluationStatus(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_get_evaluation_status`,det);
    }
    updateReviewStatus(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_update_review_status`,det);
    }
    
    /****************Self Evaluation************************/
    /****************Manager Evaluation************************/
     addManagerEvaluation(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_add_manager_evaluation`,det);
    }
     updateManagerEvaluation(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_update_manager_evaluation`,det);
    }
    /****************Manager Evaluation************************/
    submitOpinionRequest(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_add_opinion_request`,det);
    }
    getOpinionRequestList(det){
    	  return this.http.post<any>(`${environment.apiUrl}/pms_get_opinion_request`,det);
    }
    getOpinionRequestDetails(det){
    	  return this.http.post<any>(`${environment.apiUrl}/pms_get_opinion_request_details`,det);
    }
    updateOpinionEvaluation(det){
    	  return this.http.post<any>(`${environment.apiUrl}/pms_update_opinion_request`,det);
    }
    getOpinionSubmissionDetails(det){
    	  return this.http.post<any>(`${environment.apiUrl}/pms_get_opinion_submission`,det);
    }
     sendReminderMailOpinionRequest(det){
    	  return this.http.post<any>(`${environment.apiUrl}/pms_opinion_request_reminder_mail`,det);
    }
    
     /****************Reviewer Evaluation************************/
     addReviewerEvaluation(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_add_reviewer_evaluation`,det);
    }
     updateReviewerEvaluation(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_update_reviewer_evaluation`,det);
    }
    reviewerEvaluationReject(det)
    {
      return this.http.post<any>(`${environment.apiUrl}/pms_add_reviewer_evaluation_reject`,det);
    }
    /****************Reviewer Evaluation************************/
    
     /****************add goal after edit************************/
    
     addEditGoal(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_add_edit_goal`,det);
    }
    
       addEditGoalDesc(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_add_edit_goal_desc`,det);
    }
    
    getEditEvaluationGoal(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_get_edit_goal`,det);
    }
     getEditEvaluationGoalDesc(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_get_edit_goal_desc`,det);
    }
    
      updateEditGoal(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_update_edit_goal`,det);
    }
    
       updateEditGoalDesc(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_update_edit_goal_desc`,det);
    }
    deleteGoal(det) {
        return this.http.post<any>(`${environment.apiUrl}/pms_delete_edit_goal`,det);
    }
    
    /****************add goal after editn************************/
    
      getConfirmUserLog(det) {
        return this.http.post<any>(`${environment.apiUrl}/user_logs`,det);
    }

    getipaddress(det)
    {
        return this.http.post<any>(`${environment.apiUrl}/ip`,det);
    }
    
}

import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
 import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
@Injectable()
export class PMSservice {
	
	currentUser;
	currentUserDetails;
	currentReviewPeriod;
    

    constructor(private http: HttpClient) { 
    
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        
    }
    postAllUser(det) {
        return this.http.post<any>(`${environment.apiUrl}/adusersync`,det);
    }
    getActiveReviewPeriod(det)
    {
    	this.currentReviewPeriod = JSON.parse(localStorage.getItem('currentReviewPeriod'));
    	
    	if(this.currentReviewPeriod==null)
      {
    	  det = { "status":"Active","emp_id":det.emp_id };
      var response= this.http.post<any>(`${environment.apiUrl}/pms_getactivereviewperiod`,det);
      
       response.subscribe(res => {
       	
      	 localStorage.setItem('currentReviewPeriod', JSON.stringify(res));
      	 
      	 this.currentReviewPeriod = res;
      	 
      	       	
      	});
      	
      	return response;
      	}
      else {
      	
      	const sequence = new Observable(this.sequenceReviewPeriod);
      	
      	return sequence;
      }
      
      
    }
    getReviewPeriod(det)
    {
    	 return this.http.post<any>(`${environment.apiUrl}/pms_getactivereviewperiod`,det);
    }
    getEmployeeList(det)
    {
      return this.http.post<any>(`${environment.apiUrl}/pms_get_employee_list`,det);
    }
     getEmployeeDetails(email)
    {
    	var det={"email":email};
      var response= this.http.post<any>(`${environment.apiUrl}/pms_get_employee_details`,det);
       response.subscribe(res => {
       	      	      	 
      	 this.currentUserDetails = res;
      	 
      	       	
      	});
      	
      	return response;
    	}
    getCurrentUserDetails()
    {
    	
    	this.currentUserDetails = JSON.parse(localStorage.getItem('currentUserDetails'));
    	if(this.currentUserDetails==null)
      {
    	var det={"email":this.currentUser.email};
      var response= this.http.post<any>(`${environment.apiUrl}/pms_get_employee_details`,det);
       response.subscribe(res => {
       	
      	 localStorage.setItem('currentUserDetails', JSON.stringify(res));
      	 
      	 this.currentUserDetails = res;
      	 
      	       	
      	});
      	
      	return response;
      	
      }
      else {
      	
      	const sequence = new Observable(this.sequenceSubscriber);
      	
      	return sequence;
      }
    }
    getProjectslist(det)
    {
    	return this.http.post<any>(`${environment.apiUrl}/pms_project_list`,det);
    }
     addProject(det)
    {
    	return this.http.post<any>(`${environment.apiUrl}/pms_project_add`,det);
    }
    updateProject(det)
    {
    	return this.http.post<any>(`${environment.apiUrl}/pms_project_update`,det);
    }
     deleteProject(det)
    {
    	return this.http.post<any>(`${environment.apiUrl}/pms_project_delete`,det);
    }
    sequenceSubscriber(observer){
     // synchronously deliver 1, 2, and 3, then complete
     var currentUserDetails = JSON.parse(localStorage.getItem('currentUserDetails'));
     observer.next(currentUserDetails);
     observer.complete();

     // unsubscribe function doesn't need to do anything in this
     // because values are delivered synchronously
     return {unsubscribe() {}};
     }
     sequenceReviewPeriod(observer){
     // synchronously deliver 1, 2, and 3, then complete
     var currentReviewPeriod = JSON.parse(localStorage.getItem('currentReviewPeriod'));
     observer.next(currentReviewPeriod);
     observer.complete();

     // unsubscribe function doesn't need to do anything in this
     // because values are delivered synchronously
     return {unsubscribe() {}};
     }
     
     addkraSetting(det)
     {
      
      return this.http.post<any>(`${environment.apiUrl}/pms_kra_setting_submission`,det);
     
     }
     updatekraSetting(det)
     {
      
      return this.http.post<any>(`${environment.apiUrl}/pms_kra_setting_update`,det);
     
     }
      getkraSetting(det)
     {
      
      return this.http.post<any>(`${environment.apiUrl}/pms_kra_setting_details`,det);
     
     }
     getkraSettingStatus(det)
     {
      
      return this.http.post<any>(`${environment.apiUrl}/pms_kra_setting_status`,det);
     
     }
     getEmployeesToManage(det)
     {
     
      return this.http.post<any>(`${environment.apiUrl}/pms_get_employees_to_manage`,det);
     
     }
      uploadDocs(det)
      {
     
      return this.http.post<any>(`${environment.apiUrl}/pms_document_upload`,det);
     
     }
     listDocumentsUploaded(det)
      {
     
      return this.http.post<any>(`${environment.apiUrl}/pms_list_document_uploads`,det);
     
     }
     deleteDocument(det)
      {
     
      return this.http.post<any>(`${environment.apiUrl}/pms_evaluation_delete_document`,det);
     
     }
     
     evaluationBandingText(band)
     {
     	var bandtxt='';
       switch(band)
       {
        case 'A':
        bandtxt='Outstanding';
        break;
        case 'B':
        bandtxt='Exceeded Expectations';
        break;
        case 'C':
        bandtxt='Met Expectations';
        break;
        case 'D':
        bandtxt='Below Expectations';
        break;
             
       }    
       
       return bandtxt; 
     
     }
     addPmsEmployeeSkill(det)
    {
      return this.http.post<any>(`${environment.apiUrl}/pms_employee_skill_add`,det);
    }
    getPmsEmployeeSkill(det)
    {
      return this.http.post<any>(`${environment.apiUrl}/pms_employee_skill_get`,det);
    }
    updatePmsEmployeeSkill(det)
    {
      return this.http.post<any>(`${environment.apiUrl}/pms_employee_skill_update`,det);
    }
     
      /********************Reward & Recognition*********************************************/
      addRewardRecognition(det)
    {
    	return this.http.post<any>(`${environment.apiUrl}/add_reward_recognition`,det);
    }
    getRewardRecognitionList(det)
    {
    	return this.http.post<any>(`${environment.apiUrl}/get_reward_recognition`,det);
    }
     updateRewardRecognition(det)
    {
    	return this.http.post<any>(`${environment.apiUrl}/update_reward_recognition`,det);
    }
       getRewardRecognitionListForApproval(det)
    {
    	return this.http.post<any>(`${environment.apiUrl}/get_reward_recognition_for_approval`,det);
    }
   
       /********************Reward & Recognition*********************************************/
      /****************************AMC*************************************************/
    getAMCList(det)
    {
    return this.http.post<any>(`${environment.apiUrl}/get_amc_list`,det);
    }
    
    getAMCDetails(det)
    {
        return this.http.post<any>(`${environment.apiUrl}/get_amc_details`,det);
    
    }
    updateAMCDetails(det)
    {
      return this.http.post<any>(`${environment.apiUrl}/update_amc_details`,det);
    }
    
   /****************************AMC*************************************************/
    
}
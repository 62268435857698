import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  currentUser=null;
   photo :File=null;
   constructor(private http: HttpClient) { 
     this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

  ngOnInit() {
  }
  onFileSelected(event)
  {
    
    this.photo=<File>event.target.files[0];

  }
  uploadFile()
  {
    const fb=new FormData();
    fb.append("photo",this.photo,this.photo.name);
    fb.append("id",this.currentUser.id);
    fb.append("fname",this.currentUser.fname);
    fb.append("lname",this.currentUser.lname);
    fb.append("email",this.currentUser.email);
    fb.append("mobile",this.currentUser.mobile);
    this.http.post('http://alf.sil.lab/api/updateuser', fb).subscribe(
      res=>{}
    );

  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

public logo_src="https://www.ivldsp.com/wp-content/uploads/2018/07/IVL_Final_Logo-copy.png";
public logo_href="#";
public logo_height="45";

  constructor() { }

  ngOnInit() {
  }

}

﻿import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable()
export class UserService {

    

    constructor(private http: HttpClient) { 
        
    }
    
    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/listuser`);
    }

    getById(det) {

      return this.http.post(`${environment.apiUrl}/getuser`,det);

    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}/createuser`, user);
    }

    update(user) {
        return this.http.post(`${environment.apiUrl}/updateuser`, user);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/users/` + id);
    }
    verify(user) {
        return this.http.post(`${environment.apiUrl}/verifyuser`, user);
    }
    resetpassword(user) {
        return this.http.post(`${environment.apiUrl}/resetpassword`, user);
    }
    createmenu(user) {
        return this.http.post(`${environment.apiUrl}/createmenu`, user);
    }
    deletemenu(user) {
        return this.http.post(`${environment.apiUrl}/deletemenu`, user);
    }
    getMenulist() {
        return this.http.get<User[]>(`${environment.apiUrl}/listmenu`);
    }
    updatemenu(user) {
        return this.http.post(`${environment.apiUrl}/updatemenu`, user);
    }
    forgotpassword(user) {
        return this.http.post(`${environment.apiUrl}/forgotpassword`, user);
    }
    getSchemes(user) {
        return this.http.post(`${environment.apiUrl}/tdsdeduction`, user);
    }
    getSections() {
        return this.http.get<User[]>(`${environment.apiUrl}/tdssection`);
    }
    getFiles(user) {
        return this.http.post(`${environment.apiUrl}/tds_submit_files_view`,user);
    }
    deleteFile(user) {
        return this.http.post(`${environment.apiUrl}/tds_submit_files_delete`, user);
    }
    checkUserByemail(user)
    {
        return this.http.post(`${environment.apiUrl}/check_user_email`, user);  
    }
    addUserAD(user)
    {
        return this.http.post(`${environment.apiUrl}/add_user_ad`, user);  
    }

    
    
}
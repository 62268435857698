import {Component,Input,OnChanges, SimpleChange,Output, EventEmitter} from '@angular/core';

import {BaMsgCenterService} from './baMsgCenter.service';
import{HeaderComponent} from '../header/header.component'
import { TdsSubmitService } from '../../../_services';

@Component({
  selector: 'ba-msg-center',
  providers: [BaMsgCenterService],
  styleUrls: ['./baMsgCenter.scss'],
  templateUrl: './baMsgCenter.html'
})
export class BaMsgCenter {

  public notifications;
  public messages:Array<Object>;
  @Input() isShowMsg: HeaderComponent;
  @Output() getCountMsgCenter = new EventEmitter<string>();
  isShow;
  showAll=false;

   constructor(private TdsSubmitService:TdsSubmitService,private _baMsgCenterService:BaMsgCenterService) {
    this.getNotifications();
    this.messages = this._baMsgCenterService.getMessages();

  }
  
  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
  this.isShow=this.isShowMsg;
  }

  async getNotifications() {
    var det={"user_id":this._baMsgCenterService.currentUser.id};
     await this.TdsSubmitService.getNotificationMsg(det).then(
      data => {
               this.notifications=data['data'];
               this.getCountMsgCenter.emit(data['unread']);
      });
   
  }
  markAllRead()
  {
    var det={"user_id":this._baMsgCenterService.currentUser.id,"is_read":1};
    this.TdsSubmitService.markAllAsRead(det).subscribe(
      data => {  
        this.getCountMsgCenter.emit('0');             
      });

  }
  showAllMsg()
  {
    this.showAll=true;
  }
  
  filterNotification()
  {
    return this.notifications.filter(msg=>this.showMsg(msg));
  }

  showMsg(msg)
  {
    if(this.showAll==true)
    {
      return true;
    }
    else
    {
      if(msg.is_read==0)
      {
        return true;
      }
      else
      {
        return false;
      }
    }

  }
  

}

﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,ValidatorFn,AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import {Md5} from "md5-typescript";


import { AlertService, UserService } from '../_services';

@Component({templateUrl: 'register.component.html',styleUrls:['register.component.css']})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    hashpass='';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private userService: UserService,
        private alertService: AlertService) { }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            fname: ['', Validators.required],
            lname: ['', Validators.required],
            mobile: ['', Validators.required],
            email: ['', Validators.required],
            password1: ['', [Validators.required, Validators.minLength(6)]],
            status:[0],
            inputCode:['',this.captchaValidator()],
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
       
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        this.hashpass= Md5.init(this.registerForm.get('password1').value);
        this.registerForm.patchValue({password1: this.hashpass});
        
        this.loading = true;
        this.userService.register(this.registerForm.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.alertService.success('Registration successful', true);
                    this.router.navigate(['/login']);
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }

  code
  inputCode
  errors
 
  onGenerateCode(code) {
     
    this.code = code
  }
 
  verify() {
    this.inputCode=this.registerForm.get('inputCode').value
    
    if (this.code === this.inputCode) {
      return {"invalid":null}
    } else {
        this.errors=true;
      this.inputCode = undefined
      return {"invalid":true}
    }
  }

    captchaValidator(): ValidatorFn {
    return (control: AbstractControl):{ [key: string]: boolean } | null  => {
        
        if (this.code === control.value) {
               return null;
          } else {        
            this.inputCode = undefined
            return {"invalid":true}
          }
       };
  }
}

﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {Md5} from "md5-typescript";
import { AlertService, AuthenticationService } from '../_services';
import { AuthService } from '../auth.service';
import {  UserService } from '../_services';

@Component({templateUrl: 'login.component.html',styleUrls:['login.component.css']})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    hashpassword='';

    /****microsoft */
    email;
    name;
    hashpass;
    /****microsoft */
  

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,private userService: UserService) {}

    ngOnInit() {

    /************Clear Local Storage,Session Storage and Cookies**********/
        localStorage.clear();
         sessionStorage.clear();

        /* var cookies = document.cookie;

        for (var i = 0; i < cookies.split(";").length; ++i)
       {
    var myCookie = cookies[i];
    var pos = myCookie.indexOf("=");
    var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
         }
         */

   /************Clear Local Storage,Session Storage and Cookies**********/


        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        // reset login status
        this.authenticationService.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.authService.getAccessToken().then(
            token =>{
                if(token)
                {
                    //console.log(token);
                    
                }
                
            }

            );
    }
    async signIn(): Promise<void> {
        await this.authService.signIn();
        
      }
      

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }
    
        this.loading = true;
        this.hashpassword=Md5.init(this.f.password.value);
        this.authenticationService.login(this.f.username.value,this.hashpassword)
            .pipe(first())
            .subscribe(
                data => {
                    if(data.api_status){
                        if(data.status==1)
                        {
                         this.router.navigate(['/pages/profilepage']);
                        }
                        else{
                            this.alertService.error("Login Failed : Verification Pending");
                            this.loading = false; 
                          
                        }
                    }
                    else{
                        this.alertService.error("Login Failed : Email or Password is incorrect");
                        this.loading = false;    

                    }
                },
                error => {
                    this.alertService.error(error);
                    this.loading = false;
                });
    }
}

   

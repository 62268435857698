import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
@Injectable()
export class TdsSubmitService {

    

    constructor(private http: HttpClient) { 
        
    }
    submitTds(det) {
        return this.http.post<any>(`${environment.apiUrl}/tds_submit`,det);
    }
    submitTdsFiles(det) {
        return this.http.post<any>(`${environment.apiUrl}/tds_submit_files`,det);
    }
    updateTds(det) {
        return this.http.post<any>(`${environment.apiUrl}/tds_submit_edit`,det);
    }
    getTdsList(det) {
        return this.http.post<any>(`${environment.apiUrl}/tds_submit_view`,det);
    }
    getTdsFiles(det) {
        return this.http.post<any>(`${environment.apiUrl}/tds_submit_files_view`,det);
    }
    deleteTds(det) {
        if(det['status']=='N')
        {
            return this.http.post<any>(`${environment.apiUrl}/tds_submit_delete`,det);
        }
        if(det['status']=='R' || det['status']=='U')
        {
            det['status']='D';
            return this.http.post<any>(`${environment.apiUrl}/tds_reject_delete`,det);
        }
        
    }
    getSectionTotal(det)
    {
        return this.http.post<any>(`${environment.apiUrl}/tds_section_total`,det);   
    }
   async getNotificationMsg(det)
    {
        return  await this.http.post<any>(`${environment.apiUrl}/notifications`,det).toPromise();   
    }
   markAllAsRead(det)
   {
       return this.http.post<any>(`${environment.apiUrl}/mark_read_notification`,det); 
   }
   sendForApproval(det)
   {
       return this.http.post<any>(`${environment.apiUrl}/send_for_approval`,det); 
   }

  checkOpenTdsForm(det)
   {
       return this.http.post<any>(`${environment.apiUrl}/open_tds_form`,det); 
   }

   getTdsformConfiguration(det)
   {
    console.log("configuration");  
    return this.http.post<any>(`${environment.apiUrl}/tds_config_details`,det); 
   }
   checkSchemeFile(det)
   {
   
    return this.http.post<any>(`${environment.apiUrl}/check_scheme_files`,det); 
   }


}
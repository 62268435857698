import {Injectable} from '@angular/core'

@Injectable()
export class BaMsgCenterService {
  currentUser;

  constructor()
  {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  private _messages = [
    {
      name: 'Nasta',
      text: 'After you get up and running, you can place Font Awesome icons just about...',
      time: '1 min ago'
    }    
  ];

  public getMessages():Array<Object> {
    
    return this._messages;
  }

  
}

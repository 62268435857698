import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';


@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router,private authService: AuthService) { }

  ngOnInit() {
    this.authService.signOut();
    localStorage.clear();
    this.router.navigate(['']);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../environments/environment';
import { RequestOptions } from '@angular/http';

@Injectable()
export class ApplicationForm {




    constructor(private http: HttpClient) {


    }



    httpHeader() {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.append('Authorization', '83264c83fc13310ea5a260e0c4665a46');
        //httpHeaders.set('Content-Type', 'application/json'); 
        let options = { headers: httpHeaders };
        return options;
    }
    checkAuthentication(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_check_auth`, det, options);
    }
    postAddProfile(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_profile`, det, options);
    }
    postUpdateProfile(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_update_profile`, det, options);
    }
    addFamilyDetails(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_family`, det, options);
    }
    addLanguageDetails(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_language`, det, options);
    }
    addQualificationDetails(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_qualification`, det, options);
    }
    addTrainingDetails(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_training`, det, options);
    }
    addTechnicalDetails(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_technical_skill`, det, options);
    }
    addWorkExperience(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_work_experience`, det, options);
    }
    addFriendsInCompany(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_friendsincompany`, det, options);
    }
    addReference(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_reference`, det, options);
    }
    addVisa(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_visa_details`, det, options);
    }
    addGrativityNominee(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_add_gratuity_nominee`, det, options);
    }
    addbankDet(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_bank_details`, det, options);
    }
    
    uploadPreOnboardingDocs(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/pre_onboarding_document`, det, options);
    }
    addPreOnboardingReference(det) {
        let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/pre_onboarding_reference`, det, options);
    }
    
    getProfileDetails(det){
    
    let options = this.httpHeader();
        return this.http.post<any>(`${environment.apiUrl}/newivlion_get_profile`, det, options);
    }
    
    




}
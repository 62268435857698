import { Component, OnInit } from '@angular/core';
import {  UserService } from '../_services';
import {Md5} from "md5-typescript";
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.css']
})
export class AuthorizeComponent implements OnInit {
  email;
  name;
  hashpass;
  mdata;
  constructor(  private router: Router,private userService: UserService) {
  
  }


  ngOnInit() {   
    
    if(localStorage.getItem('mUser')!=='')
    {
      this.mdata=JSON.parse(localStorage.getItem('mUser'));
      this.email=this.mdata.email;
      this.name=this.mdata.displayName;
      this.checkDBuser(); 
    }

    

  }
  
  checkDBuser()
  { 
    
   if(this.email)
   {
     var random=Math.floor((Math.random() * 10000) + 1);
    this.hashpass= Md5.init(random);
     var data={"email":this.email};
     var regdata={"email":this.email,"fname":this.name,"password1":this.hashpass,"status":1};
     this.userService.checkUserByemail(data).subscribe(
       res=>{

        if(res['api_status']==1)
        {
          localStorage.setItem('currentUser', JSON.stringify(res));
          this.router.navigate(['/pages/profilepage']);
        }
        if(res['api_status']==0)
        {
          this.registerUserAD(regdata);
        }

       }
     )
   }

  }

  async registerUserAD(regdata)
  {
    await this.userService.addUserAD(regdata).subscribe(
      res=>{
        if(res['api_status']==1)
        {
          this.getUserDetails(res);
        }
      }
    )

  }
  getUserDetails(res)
  {
    var data={"id":res['id']};
    this.userService.getById(data).subscribe(
        user => {   
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          if(localStorage.getItem('currentUser')!=='')
           {
            var myinterval = setInterval(() => { 
                this.router.navigate(['/pages/profilepage']); }, 1000);
           }
      return user;
  });
  }

   
  

}

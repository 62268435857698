﻿import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Md5} from "md5-typescript";
import { environment } from '../../environments/environment';

@Injectable()
export class AuthenticationService {
    public now :string = Math.floor((new Date().getTime()/1000)).toString();
    public useragent=window.navigator.userAgent;
    public secretkey='83264c83fc13310ea5a260e0c4665a46';
    public crudtoken=Md5.init(this.secretkey+this.now+this.useragent);
    

    constructor(private http: HttpClient) { }

    login(username: string, password: string) {

        

        const httpOptions = {            
            headers: new HttpHeaders({
             //'Access-Control-Allow-Origin':'http://employee.ivldsp.com',
             'X-Authorization-Token':this.crudtoken,
             'X-Authorization-Time':this.now,
              })
          };

        return this.http.post<any>(`${environment.apiUrl}/users_authenticate`,{ email: username, password1: password },httpOptions)
            .pipe(
                  map(user => {
                // login successful if there's a jwt token in the response
                if (user.api_status) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    if(user.status===1)
                    {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    }
                    return user;
                }
                else{
                    return false;
                }
                
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}
  import { Component, OnInit } from '@angular/core';
  import { Router, ActivatedRoute } from '@angular/router';
  import { AlertService, UserService } from '../_services';
import { identifierModuleUrl } from '@angular/compiler';


@Component({
  selector: 'verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

   loading=true;
   id='';

  constructor( private route:ActivatedRoute, private router: Router,private userService: UserService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.id=atob(decodeURIComponent(this.route.snapshot.paramMap.get('id')));
    
    var fb={"id":this.id,"status":1};
    this.userService.verify(fb)
     .subscribe(
        data => {
            this.alertService.success('Modified successfully', true);
            this.loading = false;
        },
        error => {
            this.alertService.error(error);
            this.loading = false;
        });
  }
  goToHome() {
    this.router.navigate(['/login']);
  }
}

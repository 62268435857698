import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { VerifyComponent } from './verify/verify.component';
import { ForgetpasswordComponent } from './forgetpassword';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { AuthorizeComponent } from './authorize/authorize.component';
import { LogoutComponent } from './logout/logout.component';




const routes: Routes = [
  { path: 'pages', loadChildren: 'app/pages/pages.module#PagesModule' },
  //{ path: '',redirectTo: 'pages', pathMatch: 'full' },
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'register', component: RegisterComponent }, 
  { path: 'verify/:id', component: VerifyComponent }, 
  { path: 'forgetpassword', component: ForgetpasswordComponent },
  { path: 'authorize', component: AuthorizeComponent},

  { path: '**',component: LoginComponent },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

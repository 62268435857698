import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserService } from '../../../@core/data/users.service';
import { AnalyticsService } from '../../../@core/utils/analytics.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';


  user: any;
  messageCount=0;
  isShowMsg=false;

  userMenu = [{ title: 'Profile' , link: 'pages/profile'},{title:'change password', link:'pages/resetpassword'}, { title: 'Log out' ,link: '/logout' }];
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserService,
              private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.userService.getUsers()
      .subscribe((users: any) => this.user = users.nick);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
  showMsg()
  {
    if(this.isShowMsg==true)
    {
      this.isShowMsg=false;
    }else
    {
      this.isShowMsg=true;
    }
  }
  getCountNotification($event)
  {
    this.messageCount=$event;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first,map } from 'rxjs/operators';
import { AlertService, UserService } from '../_services';
import { NbGlobalLogicalPosition, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { NbToastStatus } from '@nebular/theme/components/toastr/model';
import { Router } from '@angular/router';

export class requestpassword {
  
  password: string;
  id: string;
 
}

@Component({
  selector: 'forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {

  fuser={};
  currentUser: requestpassword;
  passwordForm: FormGroup;
  loading = false;
  submitted = false;

  data = [ ];
  tstatus:NbToastStatus = NbToastStatus.SUCCESS;
  ttitle='';
  tcontent='';
  constructor( private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,private toastrService: NbToastrService) {

      
     }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
     
      email: ['', [Validators.required]]
  }, );
  }

   // convenience getter for easy access to form fields
   get f() { return this.passwordForm.controls; }

   onSubmit() {
       this.submitted = true;
       if (this.passwordForm.invalid) {
        this.alertService.error('Enter valid Email Address', true);
        return;
    }
       const fb=new FormData();
       fb.append("email",this.passwordForm.get("email").value);

 
 
       this.userService.forgotpassword(fb)
           .pipe(first())
           .subscribe(
               data => {
                this.showToast(this.tstatus,"Password", "Password has been sent successfully to your mail Id");
                this.loading = false;
                setTimeout(()=>{  
                  this.router.navigate(['/login']);
             }, 3000);
                 
               },
               error => {
                   this.alertService.error(error);
                   this.loading = false;
               });
   }
   private showToast(type: NbToastStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration:4000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates:false,
    };
    const titleContent = title ? `${title}` : '';
  
    this.toastrService.show(
      body,
      titleContent,
      config);
  }

}
